import React, { Component } from 'react';
import {Link} from 'react-router-dom';
class TopEducation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contenttop : [
                {
                    id: 1,
                    classname: 'experience-box experience-box-line t3',
                    datawow: '0.3s',
                    year: 'Politécnico Grancolombiano',
                    title: 'Professional in audiovisual media',
                    text: 'I studied radio, design, film and photography.'
                },
                // {
                //     id: 2,
                //     classname: 'experience-box experience-box-line t3',
                //     datawow: '0.5s',
                //     year: 'Udemy',
                //     title: 'User Centered Design',
                //     text: 'User experience (UX) design is the process design teams use to create products.'
                // },
                // {
                //     id: 3,
                //     classname: 'experience-box t3',
                //     datawow: '0.7s',
                //     year: 'Skil Share',
                //     title: 'Interaction Design',
                //     text: 'User experience (UX) design is the process design teams use to create products.'
                // }
            ],
            contentbot : [
                {
                    id: 2,
                    classname: 'experience-box experience-box-line t3',
                    datawow: '1s',
                    year: '2018 - 2020',
                    title: 'CTO & CEO',
                    office: 'Sinectik',
                    text: 'Mind map for creatives, Creativity has everything to do with play and randomness, we have created a mind map platform for creatives where they can connect their ideas with thousands of concepts, change the links between them and create new ideas in a fun way.'
                },
                {
                    id: 3,
                    classname: 'experience-box t3',
                    datawow: '1.2s',
                    year: '2020 - 2021',
                    title: 'CTO & CEO',
                    office: 'Pictur & Isimo ',
                    text: 'Pictur is a photography learning game and the first social editing app that allows you to travel and have fun while becoming a pro.'
                },
                {
                    id: 1,
                    classname: 'experience-box experience-box-line t3',
                    datawow: '0.8s',
                    year: '2021 - 2023',
                    title: 'React native and React Js',
                    office: 'Dynamite GDI Canada',
                    text: 'My professional journey took a significant turn during my 2-year tenure at Group Dynamite, a prominent Canadian company in the retail industry. Here, I played a pivotal role in the successful development and execution of various projects. Notable among these were the creation of a new fast checkout system using React for the web, an interactive likes feature for user wishlists built with optimistic UI on React Native, and the implementation of a cutting-edge loyalty program..'
                }
            ]
        }
    }
    
    render() {
        return (
            <div className="animate-element delay5 fadeInUp">
                <div className="padding-bottom">
                    <div className="row">
                        {
                            this.state.contenttop.map(data => (
                                <div className="col-lg-4 col-12 last-col animate-element wow delay5 fadeIn" data-wow-delay={data.datawow} key={data.id}>
                                    <div className={data.classname}>
                                        <div className="box-inner">
                                        <h3 className="f-info bg-s1 color-d6">{data.year}</h3>
                                        <div className="exp-wrap">
                                            <div className="s-info mg-b11">{data.title}</div>
                                            <p>
                                            {data.text}
                                            </p>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className="row">
                    {
                        this.state.contentbot.map(data => (
                            <div className="col-lg-4 col-12 last-col animate-element wow delay5 fadeIn" data-wow-delay={data.datawow} key={data.id}>
                                <div className={data.classname}>
                                    <div className="box-inner">
                                    <h3 className="f-info bg-s1 color-d6">{data.year}</h3>
                                    <div className="exp-wrap">
                                        <div className="s-info mg-b11">{data.title} <Link to="#">{data.office}</Link></div>
                                        <p>
                                            {data.text} 
                                        </p>        
                                    </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        );
    }
}

export default TopEducation;
