import React, { Component } from 'react';
import {Link} from 'react-router-dom';
class CaseStudy extends Component {
    render() {
        return (
            <div className="tf-modal-content">
                <section className="flat-case-study s3" id="portfolio">
                    <div className="container">
                        <div className="d-md-flex">
                            <div className="col-left animate-element wow delay5 fadeInDown" data-wow-delay="0.5s">
                                <div className="image-link t1">
                                    <div className="featured-post">
                                        <a href="https://www.dynamiteclothing.com/"><img src="images/section/56.jpg" alt="images" /></a>
                                    </div>
                                </div>
                                <div className="image-link t1">
                                    <div className="featured-post">
                                        <a href="https://www.garageclothing.com/"><img src="images/section/57.jpg" alt="images" /></a>
                                    </div>
                                </div>
                                <div className="image-link t1">
                                    <div className="featured-post">
                                        <a href="https://pictur.vercel.app/"><img src="images/section/42.jpg" alt="images" /></a>
                                    </div>
                                </div>
                           
                                {/* <div className="fl-btn text-center"> 
                                    <a href="https://www.behance.net/javfranciscoa" className="f-w500">Explore more.</a>
                                </div> */}
                            </div>
                            <div className="flat-spacer" data-desktop={0} data-mobile={100} data-smobile={100} />
                            <div className="col-right animate-element wow delay5 fadeInUp" data-wow-delay="0.5s">
                                <div className="flat-title t2 mg-b60">
                                    <h4 className="sub-title mg-b13">Book</h4>
                                    <h2 className="title-section color-d12 mg-b20">Some of my projects.</h2>
                                </div>
                                <div className="pd-right">
                                <div className="image-link t1">
                                    <div className="featured-post">
                                        <a href="https://sinectik.vercel.app/"><img src="images/section/43.jpg" alt="images" /></a>
                                    </div>
                                </div>
                                <div className="image-link t1">
                                    <div className="featured-post">
                                        <a href="https://isimo.vercel.app/"><img src="images/section/58.jpg" alt="images" /></a>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default CaseStudy;
