import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import { Icon, NumberCount } from '../home02/about/index';

class About extends Component {
    render() {
        const projects = [
            'A new fast checkout build on React for the Web',
            'Interactive likes of products for the user wishlist, built using optimistic UI on React Native',
            'New loyalty program',
            'I worked on Google Analytics adding the implementation for handling hundreds of events for tracking purpose',
            'Creating Google Cloud Functions for handling logic',
          ];

          const technologies = [
            'Redux Thunk and Duck pattern',
            'React Animation libraries',
            'Firebase distribution and remote configs for handling deployment',
            'Feature flags',
            'React Navigation',
            'Higher Order Components (HOC)',
            'Salesforce Commerce Cloud Business Manager (Sfcc BM)',
          ];
        return (
            <div className="tf-modal-content">
                <section className="about-me t2 s3 shape-am position-relative" id="about">
                    <Icon />                  
                    <div className="container">
                        <div className="row position-relative">
                            <div className="col-lg-7 animate-element wow delay5 fadeInDown" data-wow-delay="0.5s">
                                <div className="flat-title t2">
                                <h4 className="sub-title mg-b22">About me</h4>
                                <h2 className="title-section color-d12 mg-b41">
I specialize in retail initiatives, emphasizing swift checkouts, engaging product likes.</h2>
                                <div className="description">
                                    <p className="lt-sp01">
                                    I work on meaningful projects like:
                                    <ul>
        {projects.map((project, index) => (
            
          <li key={index}><div style={{flexDirection: 'column'}}><img style={{width: 10, marginRight: 10}} src={`images/testimonial/0${Math.floor(Math.random() * 6) + 1}.png`}/>{project}</div></li>
        ))}
      </ul>
                                    </p>
                                    <p className="lt-sp01">
                                    I work using scrum methodology with all ceremonies I have a certificate as a member of a team on scrum from Scrum Inc.

                                    In my daily work I use technologies and libraries like:
                                    </p>
                                    <ul>
                                    {technologies.map((project, index) => (
            <li key={index}><div style={{flexDirection: 'column'}}><img style={{width: 10, marginRight: 10}} src={`images/testimonial/0${Math.floor(Math.random() * 6) + 1}.png`}/>{project}</div></li>
          ))}
                                   </ul>
                                </div>
                                </div>
                            </div>
                            <div className="fact">
                                <NumberCount />
                                <div className="btn-contact bg-s1 text-center">
                                    <h4 className="title color-d12">Email</h4>
                                    <Link to="#" className="email color-s1 color-d14">javfranciscoa@gmail.com</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>    
        );
    }
}

export default About;
