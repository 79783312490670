import React, { Component } from 'react';
import {Link} from 'react-router-dom';
class Banner extends Component {
    render() {
        return (
            <section className="banner-section s3 two-home" id="home">
                <div className="container">
                    <div className="content-text position-relative">
                        <div className="animate-element wow delay5 fadeInDown" data-wow-delay="0.3s">
                            <div className="tag color-d1 lt-sp53 mg-b24">Developer in React Native, ReactJs, MERN stack</div>
                            <h1 className="cd-headline clip is-full-width title color-d2 mg-b31">
                                <span style={{marginRight: 20}}>Hi!</span>
                                <span className="cd-words-wrapper color-d6">
                                <b className="is-visible">Im Javier</b>
                                <b>Developer</b>
                                <b>React JS</b>
                                <b>React Native</b>
                                <b>MERN</b>
                                </span>
                            </h1>
                            <p className="lt-sp02 mg-b51">
                            I have a degree in audiovisual media professional, a self-taught MERN stack developer, and a startup entrepreneur. With 2 years of experience working in the retail industry with the development of several successful projects at Group Dynamite, a prominent Canadian company, my entrepreneurial journey spans 3 years, during which I've led teams in crafting MVPs, iterating based on user feedback, and exploring innovative solutions.
                            </p>
                        </div>
                        <div className="animate-element wow delay5 fadeInUp" data-wow-delay="0.3s">
                            <div className="fl-btn btn-general btn-hv-common color-s1">
                                <a href="https://drive.google.com/file/d/1FgjIhQo1adLyLqqM8c7WhMj0BjdjIigf/view?usp=share_link" className="btn-inner f-w500 color-d6 border-corner5 lt-sp01">Download my CV</a>
                            </div>
                        </div>
                    </div>
                    <div className="featured-post border-corner-bl">
                        <img src="images/section/35.jpg" alt="images" />
                        <div className="site-list has-border text-center animate-element wow delay5 fadeInUp" data-wow-delay="0.3s">
                            <a href="https://www.behance.net/javfranciscoa" className="bg-s2"><i className="fa fa-behance" aria-hidden="true" /></a>
                        </div>
                    </div>
                    </div>
            </section>
        );
    }
}

export default Banner;
